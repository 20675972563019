import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HarryImage = "https://content.justrightsteve.com/HarrysCorner.jpg";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard() {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 810 }}>
      <CardHeader
        sx={{ color: 'black', fontSize: '28px', fontWeight: 'bold' }}
        title="Harry's Corner"
      />
      <CardMedia
        component="img"
        height="200"
        image={HarryImage}
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
        <br/>
        <br/>A Special Tribute from Harry
        <br/>
        <br/>
        <br/>The UHL Started In September 1975. A Morning Group Of About Of About 24 Skated On The Frozen Pond Of What Is Now Royal Oak Arena.
The Same Group Played At Night As The 1st Group To Ever Skate At Berkley Arena.I Came Into The League In September 1978 On A Recommendation From Pete Masalskis Who Along with Brothers Paul & Matt were Wayne State Students Living Near Me In Oak Park. 
        <br/>
        <br/>
        <br/>Pete Played Until The End Of 1982-83. Matt Left The Group In 1981 & Moved To St. Louis To Be In Aerospace Operations Which He’s Still Doing. Pete Has His Own Chiropracting Business Along With Brother Paul In Muskskegon. Paul Never Played, Just Watched.
        <br/>
        <br/>
        <br/>My Job For The 1st 3 Years Was Open The Center Ice door At Berkley Arena & Pray I Didn’t Get Splinters Doing It.  Believe Me, I Got Plenty Of Them, That’s Why I Always Carried A Box Of Band-Aids  In My Jacket Pocket.
        <br/>
        <br/>
        <br/>In September 1981 Pete Masalskis Asked Me If  I Wanted To Be A Scorekeeper. The Following week, he brought His Camera, Took Pictures  Of All Players & Put Them On A Cardboard Poster Taped To The Stick Rack By The Scorers Table.
        <br/>
        <br/>
        <br/>I Scored Over 2500 Games From September 1981 Thru March 15, 2020.Midway Thru 1981-82, Dave Rembecki Had A Crazy Idea. He Felt Everyone Knew Someone Who Was Somewhat Talented, And Fit What The Level Head That The UHL Has Always Been That Way. The UHL For 4 Weeks Skated For 2 Hours VS A  Medical Group From Wayne State That Featured Players Like Ken Kish, Don Smolenski,Terry Connelly, Mark Saunders, Pete Schummer. Among Others. The UHL Played 4 weeks Against Them With A Record Of 1 Win, 6 Losses & 1 Tie.
        <br/>
        <br/>
        <br/>The 1 win &1 Tie Was During The Final week. Those 4 Weeks Sowed The Seed For What The UHL Does Today.  On The Sunday After Labor Day 1983, 4 Team Hockey On A Full Time Basis Was Born.  Original President Roger Willis, VP Jim French, Treasurer “Georgeous” Phil Guastella & Captains Jim French, Mark Jordan, Mark Saunders & Terry Connelly Began what The League does Today. The Original 4 Goalies Were Phil Guastella, Tim Smith, Doug DiCicco & Kent Ludwig.
The UHL Played At Berkley Arena Full Time From September 1975 Until October 2016 When The Heating & Cooling System Conked Out Completely 3 weeks. 
        <br/><br/>
        <br/>Into The 2016-17 Season & Closed Permanently & Was Torn down In 2019.  The UHL Moved To Hazel Park In October 2017.
March 15, 2020 Was 1 Of The Most Bizzare Nights Ever In UHL History.When I  Walked In At 8:30 PM With Matt Schellenberg, I Knew The Season was Over.  All Lobby Benches were Spread 6 Feet Apart. 6 Foot Markings Were All Over The Floors.Everything Was Being Emptied Out. At The Scorers Table Was PPE Goggles That I Was Forced To Wear.
        <br/>
        <br/>
        <br/>I Couldn’t Talk Thru Them At All & Took Them Off Midway Thru The 9 PM Game.Management Was Pissed. They Almost Threw Me Out. I Finally Convinced Them To Let Me Finish & I’d Tell Everyone That The 2019-2020 Season  Was Over.
        <br/>
        <br/>
        <br/>When September 2020 Came & I Saw The Last Part Of The Protocol That Forced All Scorekeepers To Wear PPE Goggles At All Times While Scoring Games, That’s When I Decided To Leave The Scorers Table Permanently After 39 Tears.  Unfortunately, My Writing Skills Have Taken A Trip South & I Can No Longer Score Games. 
        <br/>
        <br/>
        <br/>I Have To Write & Print Slow Poke Style Until My Right Hand Stops Shaking. What Makes Me So Happy Today Is I see The Sons, Brothers, Perhaps Cousins Of Players Still In The League Now Coming Into The UHL. Seeing Brad & Justin Berlin Keeping The Berlin Tradition Alive. 
        <br/>
        <br/>
        <br/>The League Needs The Younger Players ToTake The UHL To The Next Level. It’ll Be A Process, But It will Be A Huge Success.
        <br/>
        <br/>
        <br/>Finally, I Never Thought I’d See The Day Where The UHL would Have It’s Own Line Of Wearables. I’ve Gotta Get Some Of It Down The Road.
        <br/>
        <br/>
        <br/>There You Have It. 50 Years Of The UHL.  Raise A Glass. Have A Ginger Ale  For Many Years To Come.
        <br/>
        <br/>
        <br/> - Harry Goldman
        </Typography>
      </CardContent>
      {/* <CardContent>
        <Typography variant="h5">
          Last Week's Trivia
        </Typography>
      </CardContent>
      <CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
         <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
          <br/> UHL TRIVIA ANSWER: In 2001-02 Ryan Berlin Set The UHL Record For Most Penalty Minutes In A Season with 91 Penalty Minutes.
         </Typography>
        </CardContent>
      </Collapse> */}
    </Card>
  );
}
