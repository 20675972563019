import React from "react";
import { Typography} from "@mui/material";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const ImageUrl = "https://content.justrightsteve.com/IMG_4855.png";

function GroupPhotoComponent() {
  return (
    <Card sx={{ maxWidth: 810 }}>
      <CardHeader
        sx={{ color: 'black', fontSize: '28px', fontWeight: 'bold' }}
        title=""
      />
      <CardMedia
        component="img"
        height="350"
        image={ImageUrl}
      />
<CardContent>
  <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.6 }}>
    <br />Congratulations to Team Hawkins!  They are your 2024-2025 UHL Champs!
    <br />
    <br />Way to go boys!
  </Typography>
</CardContent>
    </Card>
  );
}

export default GroupPhotoComponent;
